$black: #000;
$white: #fff;
$bg-blue: #eff0ff;
$primary: #5a58dd;
$border: #c3cad9;
$title: #002d37;
$text-gray: #4c4f5a;
$pink: #e4d4ff;

@mixin bg-overlay {
  backdrop-filter: blur(54px);
  background: rgba(10, 44, 55, 0.5);
}
