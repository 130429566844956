@font-face {
    font-family: 'Helvetica';
    src: url(../fonts/HelveticaRegular.eot);
    src: url(../fonts/HelveticaRegular.eot?#iefix)
        format('embedded-opentype'),
      url(../fonts/HelveticaRegular.otf) format('otf'),
      url(../fonts/HelveticaRegular.svg#HelveticaRegular)
        format('svg'),
      url(../fonts/HelveticaRegular.ttf) format('truetype'),
      url(../fonts/HelveticaRegular.woff) format('woff'),
      url(../fonts/HelveticaRegular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica';
    src: url(../fonts/HelveticaBold.eot);
    src: url(../fonts/HelveticaBold.eot?#iefix)
        format('embedded-opentype'),
      url(../fonts/HelveticaBold.otf) format('otf'),
      url(../fonts/HelveticaBold.svg#HelveticaBold)
        format('svg'),
      url(../fonts/HelveticaBold.ttf) format('truetype'),
      url(../fonts/HelveticaBold.woff) format('woff'),
      url(../fonts/HelveticaBold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
  }