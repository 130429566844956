body.hiden {
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  background: $white;
  color: $black;
}

h1 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
.col-1 {
  grid-template-columns: repeat(1, 1fr) !important;
}
.container {
  padding: 20px;
}
.mt-20 {
  margin-top: 20px;
}

.header__menu-btn {
  display: none;
  width: 18px;
  height: 12px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.header__menu-btn span {
  display: block;
  position: absolute;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 2px;
  width: 100%;
  background: $title;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.header__menu-btn span:nth-child(1) {
  top: 0px;
}

.header__menu-btn span:nth-child(2),
.header__menu-btn span:nth-child(3) {
  top: 5px;
  width: 100%;
}

.header__menu-btn span:nth-child(4) {
  top: 10px;
  width: 100%;
  right: 0;
  left: inherit;
}
.header__menu-btn.open span {
  border-radius: 10px;
}
.header__menu-btn.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
  display: none;
}

.header__menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  display: none;
}

.subscribe {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  input {
    height: 100%;
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    padding: 0px 10px;
    background-color: $black;
  }
  input::placeholder {
    color: #616161;
  }
  button {
    height: 100%;
    padding: 0px 22px;
    background-color: $black;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  button:hover {
    color: $black;
    background-color: $white;
  }
}
.btn {
  border-radius: 5px;
  background: $black;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  color: $white;
  padding: 15px;
  min-height: 55px;
  transition: all 0.3s ease;
  min-width: 215px;
}
.btn:hover {
  background: $white;
  color: $black;
}

.form-input-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.done {
    label {
      color: $black;
    }
  }
  label {
    color: $black;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  input {
    width: 100%;
    padding: 0px 5px;
    height: 35px;
    border-bottom: 1px solid $black;
    color: $black;
  }
}
.form-input-item:hover {
  input {
    border-bottom: 1px solid $black;
  }
  label {
    color: $black;
  }
}

.checkbox-item {
  padding-left: 27px;
  display: flex;
  align-items: center;
  .checkbox-radio {
    display: none;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1_771)'%3E%3Cpath d='M5.80556 10.5388L6.20153 10.8748L6.52229 10.4664L10.2646 5.70152L10.5679 5.31529L10.1872 5.00507L9.51117 4.45426L9.11661 4.13277L8.80217 4.53297L5.9319 8.18606L4.06752 6.60389L3.67938 6.2745L3.35702 6.66849L2.80235 7.34642L2.49136 7.72652L2.86583 8.04428L5.80556 10.5388ZM0.5 7.32408C0.5 5.69792 1.13433 4.14018 2.26059 2.99306C3.38654 1.84626 4.91169 1.2037 6.5 1.2037C8.08831 1.2037 9.61346 1.84626 10.7394 2.99306C11.8657 4.14018 12.5 5.69792 12.5 7.32408C12.5 8.95023 11.8657 10.508 10.7394 11.6551C9.61346 12.8019 8.08831 13.4444 6.5 13.4444C4.91169 13.4444 3.38654 12.8019 2.26059 11.6551C1.13433 10.508 0.5 8.95023 0.5 7.32408Z' fill='black' stroke='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1_771'%3E%3Crect width='13' height='13.2407' fill='white' transform='translate(0 0.703705)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      border-radius: 50%;
      left: -27px;
      top: 3px;
      transform: scale(0) rotateZ(180deg);
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background-color: #e0e0e0;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      left: -27px;
      top: 3px;
    }
  }
}

.checkbox-radio:checked + label::before {
  transform: scale(1) rotateZ(0deg);
}

.g-select {
  width: 100%;
  font-size: 12px;
  line-height: 150%;
  color: #4c4f5a;
  height: 38px;
  background-color: #fdfdff;
  border-radius: 20px;
  padding: 0 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='4' height='3' viewBox='0 0 4 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 3L0.267949 0.75H3.73205L2 3Z' fill='%234C4F5A'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-repeat: no-repeat;
  outline: none;
}
.input-search {
  position: relative;
  input {
    width: 100%;
    font-size: 12px;
    line-height: 150%;
    color: #4c4f5a;
    height: 38px;
    background-color: #fdfdff;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 40px;
    &::placeholder {
      color: #4c4f5a;
    }
  }
  svg {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.multi-tag {
  display: flex;
  align-items: center;
  background-color: #eff2f9;
  border-radius: 50px;
  max-width: 292px;
  width: 100%;
  height: 34px;
  &-icon {
    height: 34px;
    min-width: 34px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $pink;
    margin-right: 13px;
  }
  &-text {
    font-size: 12px;
    line-height: 150%;
    color: $text-gray;
    span {
      font-weight: 700;
    }
  }
}
.g-button {
  max-width: 217px;
  width: 100%;
  height: 50px;
  border-radius: 200px;
  border: 4px solid #bdbcf1;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  color: $white;
  gap: 10px;
}
.g-button.g-button--gray {
  border-color: #cbcbcb;
  background-color: $text-gray;
  svg path {
    stroke: $white;
  }
}
.g-button.g-button--green {
  border-color: #b6ffea;
  background-color: #93e3cc;
  color: #4c4f5a;
}

.d-button {
  max-width: 218px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  color: $black;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $black;
  border-radius: 200px;
}
.small-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  max-width: 176px;
  width: 100%;
  background-color: $primary;
  font-weight: 700;
  font-size: 14px;
  color: $white;
  border-radius: 50px;
  height: 28px;
}
.full-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 66px;
  background-color: #eff2f9;
  border-radius: 50px;
  gap: 3px;
}
.full-btn.full-btn--green {
  background-color: rgba(147, 227, 204, 0.32);
  border: 1px solid #93e3cc;
  color: $title;
  text-decoration: underline;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.list.list--twocol {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.edit-btn {
  margin-left: 10px;
}
.c-input.border {
  .c-input-con input {
    border-radius: 20px;
  }
}
.c-input {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 162%;
    letter-spacing: 0.01em;
    color: #23272b;
  }
  .c-input-con {
    width: 100%;
    display: flex;

    input {
      width: 100%;
      height: 60px;
      padding: 0px 12px;
      border: 1px solid $border;
      border-radius: 10px;
      &::placeholder {
        color: #c3cad9;
      }
    }
  }
}
.button-upload {
  width: 100%;
  height: 60px;
  padding: 0px 12px;
  border: 1px solid $border;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-textarea {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 162%;
    letter-spacing: 0.01em;
    color: #23272b;
  }
  .c-textarea-con {
    width: 100%;
    display: flex;
    align-items: flex-end;
    textarea {
      width: 100%;
      padding: 18px 12px;
      border: 1px solid $border;
      border-radius: 10px;
      resize: none;
      min-height: 120px;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 50px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.1);
}

input:checked + .slider {
  background-color: #34c759;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.interface-panel-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.interface-panel-select {
  display: flex;
  align-items: center;
  gap: 42px;
  .g-select {
    width: 262px;
    border: 1px solid $border;
  }
}
.interface-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 65px 20px 50px;
  border: 1px solid $border;
  border-radius: 20px;
  gap: 20px;
}
.interface-panel-left {
  max-width: 900px;
  width: 100%;
}
.interface-panel.logout {
  .interface-panel-title {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.interface-panel-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: $text-gray;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #4c4f5a;
}
.interface-panel-description {
  font-size: 12px;
  line-height: 179%;
  color: $text-gray;
}
.link-back {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  text-decoration: underline !important;
  color: $text-gray;
}

.tab {
  display: flex;
  align-items: center;
  height: 66px;
  background-color: #eff2f9;
  border-radius: 50px;
  padding: 5px;
  .tab-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: 100%;
    border-radius: 50px;
    font-size: 12px;
    line-height: 150%;
    color: rgba(76, 79, 90, 0.48);
    cursor: pointer;
    &.active {
      background-color: $title;
      color: $white;
    }
  }
}
.link {
  display: flex;
  justify-content: flex-end;
  a {
    font-size: 10px;
    line-height: 179%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: $text-gray;
  }
}
.edit-textarea-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .edit-btn {
    margin-left: 0px;
    margin-top: 15px;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    text-decoration: underline;
    color: $black;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.edit-textarea {
  width: 100%;

  background-color: #eff2f9;
  border-radius: 20px;
  padding: 10px 56px 17px 52px;
  label {
    font-weight: 700;
    font-size: 12px;
    line-height: 179%;
    color: $text-gray;
  }
}
.edit-textarea {
  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
    height: 70px;
    resize: none;
    font-size: 12px;
    line-height: 179%;
    color: $text-gray;
  }
}
.notification-icon {
  width: 34px;
  height: 34px;
  background-color: rgba(228, 212, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-icon {
  padding: 5px 26px;
  background-color: $pink;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: $text-gray;
}
.g-table {
  width: 100%;
  border-collapse: collapse;
  a {
    font-size: 12px;
    line-height: 179%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: $text-gray;
  }
  th:nth-child(2) {
    width: 30%;
  }
  th:nth-child(3) {
    width: 30%;
  }
  th {
    text-align: left;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color: $title;
    padding-bottom: 8px;
  }
  td:first-child {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $title;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  td {
    padding: 20px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $text-gray;
    border-top: 1px solid rgba(195, 202, 217, 0.2);
  }
}
.g-table-user {
  display: flex;
  align-items: center;
  gap: 10px;
}
.g-table-user-img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border: 3px solid #4c41ad;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.g-table-user-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.g-table-user-title {
  font-size: 12px;
  line-height: 179%;
  color: $text-gray;
}
.g-table.g-table-employer {
  .g-table-user-img {
    width: 82px;
    height: 82px;
    border: 1px solid $border;
  }
}
.g-table-con {
  display: flex;
  align-items: center;
  gap: 45px;
}

.popup {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 40;
  .c-input {
    gap: 13px;
  }
}
.popup-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background: rgba(10, 44, 55, 0.5);
}
.popup-close {
  position: absolute;
  right: 40px;
  top: 13px;
  color: $white;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 40;
  svg path {
    fill: $white;
  }
}
.popup-inner {
  z-index: 1;
  overflow: auto;
  width: 100%;
  display: flex;
  padding: 40px 20px;
}
.popup-box {
  position: relative;
  background: $white;
  border-radius: 50px;
  padding: 20px;
  margin: auto;
  min-height: 580px;
  max-width: 1040px;
  width: 100%;
  display: flex;
  .popup-box-close {
    position: absolute;
    right: 19px;
    top: 18px;
    cursor: pointer;
  }
  .popup-box-icon {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.popup-box-inner {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  max-width: 693px;
  width: 100%;
  margin: 0 auto;
}

.popup-Notification {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-icon {
    margin-bottom: 10px;
  }
  &-title {
    margin-bottom: 120px;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #002d37;
    max-width: 355px;
  }
  &-close {
    font-size: 14px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: #0a2c37;
  }
  a {
    font-size: 14px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: #0a2c37;
    margin-bottom: 10px;
  }
}
