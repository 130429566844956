@import 'fonts';
@import 'vars';
@import 'reset';
@import 'global';
.aside {
  position: fixed;
  left: 0;
  top: 0;
  width: 65px;
  height: 100%;
  border-right: 1px solid #eff2f9;
  background-color: $white;
  padding-top: 100px;
  z-index: 20;
}
.aside__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 27px 0;
}
.aside__inner-chat {
  margin: auto 0;
  width: 100%;
  a {
    font-size: 0px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #b1b8c6;
  }
  a.active {
    color: #002d37;
  }
}
.aside__inner-nav {
  width: 100%;
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    li {
      width: 100%;
      display: flex;
      justify-content: center;
      a {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 0px;
        color: #b1b8c6;
      }
      a.active {
        color: #002d37;
        &::after {
          content: '';
          position: absolute;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 40px;
          background-color: #e4d4ff;
        }
        svg path {
          stroke: #002d37;
        }
      }
    }
  }
}
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: $white;
  z-index: 30;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  border-bottom: 1px solid #eff2f9;
  padding: 0 25px;
  .header__inner-logo {
    position: relative;
    z-index: 3;
    a {
      display: flex;
    }
  }
}
.header__inner-nav {
  display: flex;
  align-items: center;
  .nav-list {
    display: flex;
    align-items: center;
    > li {
      & + li {
        margin-left: 47px;
      }
      a {
        color: $black;
      }
      a:hover {
        color: $black;
      }
      a.active {
        font-weight: 500;
        color: $black;
      }
    }
    > a {
      margin-left: 48px;
    }
    .nav-list-item {
      position: relative;

      .nav-list-item-drop-down {
        visibility: hidden;
        z-index: 1;
        transform: translate(0px, 10%);
        opacity: 0;
        position: absolute;
        left: -15px;
        top: 40px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
        padding: 8px 0px;
        pointer-events: none;
        transition: all 0.3s ease;
        min-width: 150px;
        ul li {
          display: flex;
          a {
            padding: 5px 20px;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
      &.drop-down.active {
        .nav-list-item-drop-down {
          visibility: visible;
          transform: translate(0px, 0px);
          opacity: 1;
          pointer-events: all;
        }
        .nav-list-item-btn svg {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    .nav-list-item.drop-down .nav-list-item-btn {
      display: flex;
      align-items: center;
      position: relative;
      padding-right: 20px;
      cursor: pointer;

      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 7px;
        transition: all 0.3s ease;
      }
    }
  }
}
.header__inner-link {
  display: flex;
  align-items: center;
  gap: 15px;
  a {
    display: flex;
  }
}
.header__inner-welcome {
  font-size: 12px;
  line-height: 150%;
  a {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: $primary;
  }
}
.main {
  padding-top: 100px;
  padding-left: 65px;
}
.filter {
  background-color: $bg-blue;
  padding: 15px 22px 25px 15px;
  border-radius: 20px;
  margin-bottom: 25px;
}
.filter-top {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px 10px;
  align-items: center;
}
.filter-top-left {
  display: flex;
  align-items: center;
  gap: 20px;
  grid-column: 1 / 5;
}
.filter-top-right {
  grid-column: 5 / 6;
}

.filter-top-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $title;
}
a.filter-top-save {
  font-size: 10px;
  line-height: 179%;
  text-decoration: underline;
  color: $text-gray;
}
.filter-items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px 10px;
  margin-top: 15px;
  .g-button {
    height: 100%;
    max-width: 100%;
  }
}

.job-advert {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 18px 33px 40px;
  border: 1px solid $border;
  border-radius: 20px;
  position: relative;
  &-left {
    max-width: 292px;
    width: 100%;
    padding-right: 50px;
    margin-right: 35px;
    border-right: 1px solid $pink;
  }
  &-title {
    font-weight: 700;
    font-size: 31px;
    line-height: 150%;
    color: $title;
    margin-bottom: 25px;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
  &-right {
    display: flex;
  }
  &-favorite {
    max-width: 35px;
    width: 100%;
    margin-left: 15px;
    button {
      display: flex;
    }
  }
  &-con {
    display: flex;
    flex-direction: column;
  }
  &-skills {
    display: flex;
    align-items: center;
    gap: 55px;
    margin-bottom: 48px;
    &-item {
      display: flex;
      align-items: center;
      gap: 15px;

      &-title {
        font-weight: 700;
        font-size: 10px;
        line-height: 150%;
        color: $title;
      }
      &-list {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
      }
    }
  }
  &-info {
    display: flex;
    align-items: center;
    gap: 48px;
    margin-bottom: 25px;
    &-item {
      display: flex;
      align-items: center;
      &-icon {
        margin-right: 10px;
        display: flex;
      }
      &-text {
        font-size: 12px;
        line-height: 150%;
        color: #4c4f5a;
      }
      &-label {
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        color: #4c4f5a;
        margin-right: 5px;
      }
    }
  }
  &-description {
    padding: 33px 35px;
    background-color: $bg-blue;
    border-radius: 20px;
    margin-bottom: 15px;
    p {
      font-size: 12px;
      line-height: 179%;
      color: $text-gray;
      -webkit-line-clamp: 2; /* Число отображаемых строк */
      display: -webkit-box; /* Включаем флексбоксы */
      -webkit-box-orient: vertical; /* Вертикальная ориентация */
      overflow: hidden; /* Обрезаем всё за пределами блока */
    }
  }
  &-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}
.profile-form {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding-left: 48px;
  margin-bottom: 50px;
}
.profile-form-con {
  display: flex;
  flex-direction: column;
  max-width: 1052px;
  width: 100%;
  gap: 30px;
}
.profile-form-con-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 65px;
}
.profile-form-con-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 115%;
  letter-spacing: 0.01em;
  color: #23272b;
}
.profile-form-line {
  margin-bottom: 25px;
  margin-top: 50px;
  width: 100%;
  height: 1px;
  background-color: $border;
}
.profile-form-con-box.label {
  .c-input label {
    font-weight: 400;
  }
}
.search-name-lists {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 18px;
}
.search-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 55px 40px 45px;
  border: 1px solid $border;
  border-radius: 20px;
}
.search-name-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 150%;
  color: $title;
  margin-bottom: 30px;
}
.search-name-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-name-tags {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 17px 45px;
}
.search-name-btn {
  display: flex;
  align-items: center;
  .g-button {
    width: 217px;
    margin-right: 18px;
  }
}
.cvs-lists {
  margin-bottom: 18px;
}
.cvs-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 55px 40px 45px;
  border: 1px solid $border;
  border-radius: 20px;
}
.cvs-item-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 150%;
  color: $title;
  margin-bottom: 30px;
}
.cvs-item-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cvs-item-tag {
  display: flex;
  align-items: center;
  gap: 25px;
  max-width: 315px;
  width: 100%;
  button {
    display: flex;
  }
}

.cvs-item-btn {
  max-width: 217px;
  width: 100%;
}

.interface-panel.logout {
  .interface-panel-right {
    max-width: 176px;
    width: 100%;
  }
}

.notif-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $border;
  border-radius: 20px;
  padding: 20px 48px 20px 43px;
  &-left {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  &-img {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    border: 1px solid $border;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    &-title {
      font-weight: 700;
      font-size: 25px;
      line-height: 150%;
      color: $title;
    }
    &-label {
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      color: $text-gray;
    }
    &-btn {
      .small-button {
        background-color: #93e3cc;
      }
    }
  }
  &-addrerss {
    display: flex;
    flex-direction: column;
    &-title {
      font-weight: 700;
      font-size: 25px;
      line-height: 150%;
      color: $title;
    }
    &-subtitle {
      font-size: 20px;
      line-height: 150%;
      color: $title;
      display: flex;
      align-items: center;
      gap: 2px;
      svg circle {
        fill: $white;
      }
    }
  }
  &-right {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    max-width: 362px;
    width: 100%;
  }
}
.notif-item-posted {
  display: flex;
  flex-direction: column;
  &-title {
    font-size: 18px;
    line-height: 150%;
    color: $title;
    span {
      font-weight: 700;
    }
  }
  &-closing {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #e43434;
  }
}
.notif-item-Applicants {
  max-width: 180px;
  width: 100%;
  height: 88px;
  .post-job-views {
    max-width: 100%;
    border: 1px solid $primary;
    border-radius: 10px;
  }
}
.post-job {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 35px 30px 30px 43px;
  border-radius: 20px;
  border: 1px solid $border;
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 150%;
    color: $title;
    max-width: 220px;
    width: 100%;
  }
  &-views {
    max-width: 125px;
    width: 100%;
    height: 90px;
    border-radius: 21px;
    background-color: #eff2f9;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    &-num {
      font-weight: 700;
      font-size: 30px;
      color: $title;
    }
    &-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: $title;
    }
  }
  &-btn {
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    .g-button {
      height: 42px;
    }
    .g-button:first-child {
      border: none;
      background-color: #e4d4ff;
      color: #4c41ad;
      svg path {
        stroke: #4c41ad;
      }
    }
    .g-button:last-child {
      svg path {
        stroke: #fff;
      }
    }
  }
  &-tag {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 17px;
    .multi-tag {
      background-color: transparent;
    }
  }
}
.post-job.post-job--live {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .post-job-views {
    max-width: 180px;
    height: 115px;
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 10px;
  }
  .post-job-btns {
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    .g-button:nth-child(2) {
      border-color: #cbcbcb;
      background-color: #4c4f5a;
    }
    button svg path {
      stroke: $white;
    }
  }
  .post-job-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .post-job-link {
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: $black;
  }
}
.filter-talent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $bg-blue;
  border-radius: 20px;
  overflow: hidden;
}
.filter-talent-head {
  padding: 22px 23px 22px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e4f1;
  & > .g-button {
    height: 38px;
    max-width: 185px;
  }
}
.filter-talent-head-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $title;
}
.filter-talent-head-select {
  max-width: 175px;
  width: 100%;
  .g-select {
    border-radius: 0px;
  }
}
.filter-talent-head-btn {
  display: flex;
  align-items: center;
  gap: 60px;
  &-item {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    line-height: 150%;
    color: $title;
  }
}
.filter-talent-head-traffic {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  &-text {
    font-size: 12px;
    line-height: 150%;
    color: $text-gray;
  }
  &-icon {
    display: flex;
    align-items: center;
    gap: 13px;
  }
}
.filter-talent-head-switch {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &-text {
    font-size: 12px;
    line-height: 150%;
    color: $text-gray;
  }
  .switch {
    min-width: 45px;
    height: 22px;
  }
  .slider:before {
    width: 18px;
    height: 18px;
  }
  input:checked + .slider:before {
    transform: translateX(23px);
  }
}

.filter-talent-filters {
  display: flex;
  flex-direction: column;
  padding: 15px 45px 25px 35px;
  position: relative;
  & + &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    width: 93%;
    height: 1px;
    background-color: #e1e4f1;
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: $title;
    }
    &-close {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 300;
      font-size: 12px;
      line-height: 150%;
      text-decoration: underline;
      text-decoration-skip-ink: none;
      color: $title;
    }
  }
  &-selects {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    .g-select {
      height: 46px;
    }
  }
}

.job-advert.talent-item {
  .job-advert-box {
    display: flex;
  }
  .job-advert-box-panel {
    margin-left: 10px;
    padding: 10px 13px;
    background-color: #efe4fa;
    max-width: 152px;
    width: 100%;
    border-radius: 20px;
  }
  .job-advert-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
    .job-advert-img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 2px solid $primary;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .small-button {
      max-width: 100%;
      background-color: $bg-blue;
      color: $primary;
      svg path {
        stroke: $primary;
      }
    }
  }
}
.job-advert-box-panel-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 179%;
  color: $text-gray;
  padding-bottom: 3px;
  border-bottom: 1px solid $black;
  margin-bottom: 18px;
}
.job-advert-box-panel-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.job-advert-box-panel-item-text {
  font-size: 10px;
  line-height: 150%;
  color: #4c4f5a;
}
.job-advert-box-panel-item-title {
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;
  color: #4c4f5a;
}

.job-advert-panel {
  max-width: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-left: 35px;
  gap: 20px;
}
.job-advert-panel-status {
  padding: 17px 24px;
  background-color: #f4f6fb;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: fit-content;
}
.job-advert-panel-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 179%;
  text-align: center;
  color: #4c4f5a;
  max-width: 75px;
}
.profile-form-con-box.col-1 {
  gap: 30px;
}
.a-talent-item {
  display: flex;
  flex-direction: column;
  border: 1px solid $border;
  border-radius: 20px;
  .g-button {
    height: 37px;
  }
}
.a-talent-item-head {
  padding: 20px 45px 20px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.a-talent-item-text {
  display: flex;
  flex-direction: column;
}
.a-talent-item-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 150%;
  color: $title;
}
.a-talent-item-subtitle {
  font-size: 20px;
  line-height: 150%;
  color: $title;
}
.a-talent-item-tag {
  display: flex;
  align-items: center;
  gap: 35px;
  .multi-tag {
    min-width: 255px;
    width: 100%;
  }
}
.a-talent-item-btn {
  display: flex;
  flex-direction: column;
  max-width: 205px;
  width: 100%;
  gap: 10px;
}
.a-talent-item-dropdown {
}
.a-talent-item-edit {
  display: flex;
  flex-direction: column;
  padding: 27px 47px 32px 40px;
  gap: 40px;
}
.a-talent-item-edit-textarea {
  display: flex;
  gap: 27px;
}
.a-talent-item-edit-btns {
  display: flex;
  justify-content: center;
}
.a-talent-item-edit-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  .g-button {
    min-width: 206px;
  }
}
.a-talent-item-table {
  width: 100%;
  padding: 33px 52px 38px 38px;
  background-color: #f9fbff;
  table {
    width: 100%;
    border-collapse: collapse;
    th:nth-child(2) {
      width: 30%;
    }
    th {
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: $title;
      padding-bottom: 8px;
    }
    td:first-child {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $title;
      display: flex;
      align-items: center;
      gap: 6px;
      a {
        font-weight: 700;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        color: $title;
      }
    }
    td {
      padding: 20px 0px;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: $text-gray;
      border-top: 1px solid rgba(195, 202, 217, 0.2);
    }
  }
}
.t-btn-con {
  display: flex;
  align-items: center;
  gap: 15px;
}
.t-btn-edit {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  line-height: 150%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #0a2c37;
  opacity: 0.4;
}
.t-btn-action {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  line-height: 150%;
  text-decoration: underline;
  color: #000;
}
.t-indecator {
  padding: 3px 30px;
  background-color: #b1b8c6;
  border-radius: 200px;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: $text-gray;
}
.t-indecator.t-indecator--yes {
  background-color: #93e3cc;
}
.t-indecator.t-indecator--no {
  background-color: #e43434;
  color: $white;
}
.a-talent-item.a-employers-item {
  .a-talent-item-title {
    max-width: 185px;
    width: 100%;
  }
  .multi-tag {
    min-width: 165px;
  }
  .post-job-views {
    gap: 0px;
  }
  .post-job-views-num {
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    color: #4c4f5a;
  }
  .post-job-views-label {
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #0a2c37;
  }
  .a-talent-item-edit-btns {
    justify-content: space-between;
  }
  .t-btn-con {
    .t-btn-edit {
      opacity: 1;
    }
  }
  .a-talent-item-table table th:nth-child(3) {
    width: 30%;
  }
}
.a-employers-item-tag {
  display: flex;
  align-items: center;
  gap: 10px;
}
.a-employers-item-tag-item {
  min-width: 165px;
  width: 100%;
  background-color: #eff2f9;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  font-size: 12px;
  line-height: 150%;
  color: $text-gray;
}
.t-link-con {
  display: flex;
  align-items: center;
  gap: 25px;
  a {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 12px;
    line-height: 150%;
    text-decoration: underline;
    color: $text-gray;
  }
}
.notif-item.admin-notif-item {
  button {
    height: 37px;
  }
}
.notif-item.new-talent {
  .notif-item-text {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 48px;
  }
  .notif-item-left {
    gap: 20px;
  }
  a {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: $text-gray;
  }
}
.acc-popup-setting {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  .c-input + .c-input {
    margin-top: 30px;
  }
  > button {
    margin-top: 57px;
  }
}
.c-input.button-upload--gray {
  .button-upload {
    border-radius: 27px;
    background-color: #eff2f9;
    padding-left: 27px;
    padding-right: 19px;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color: #4c4f5a;
  }
}

@import 'media';
