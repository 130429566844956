@media (max-width: 1300px) {
  .job-advert-title {
    font-size: 20px;
  }
  .job-advert-left {
    padding-right: 20px;
    margin-right: 20px;
  }
  .job-advert {
    padding-left: 20px;
  }
}

@media (max-width: 1200px) {
  .footer .footer__inner .footer__inner-items .footer__inner-item:first-child {
    max-width: 230px;
  }
}
@media (max-width: 1024px) {
  .contact
    .contact__inner-card
    .form-input-inner
    .form-input-item
    + .form-input-item {
    margin-left: 20px;
  }
  .contact .contact__inner-card .contact__inner-card-form {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
  }
  .footer .footer__inner .footer__inner-items {
    flex-wrap: wrap;
    gap: 30px;
  }
  .header__inner-link {
    margin-left: 30px;
    a + a {
      margin-left: 15px;
    }
  }
  .header__inner-nav .nav-list > li + li {
    margin-left: 25px;
  }
  .job-advert-skills {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .job-advert {
    flex-direction: column;
  }
  .job-advert-left {
    padding-right: 0px;
    margin-right: 0px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-right: none;
    border-bottom: 1px solid #e4d4ff;
    max-width: 100%;
  }
  .job-advert-list {
    .multi-tag {
      max-width: 100%;
    }
  }
  .job-advert-favorite {
    position: absolute;
    right: 15px;
    top: 10px;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .filter-top-right {
    max-width: 200px;
    width: 100%;
  }
  .filter-top-select {
    width: 100%;
  }
  .filter-top {
    display: flex;
    justify-content: space-between;
  }
  .filter-items {
    display: flex;
    grid-template-columns: unset;
    overflow-x: auto;
    .g-select {
      min-width: 100px;
    }
    .input-search {
      order: -1;
      min-width: 150px;
    }
  }
  .main {
    padding-left: 0px;
    padding-top: 80px;
  }
  .aside__inner {
    align-items: flex-start;
  }
  .aside {
    left: -255px;
    width: 255px;
    transition: all 0.3s ease;
    padding-top: 80px;
  }
  .aside__inner-nav ul li a {
    justify-content: flex-start;
    padding: 0px 30px;
    font-size: initial;
    svg {
      margin-right: 10px;
    }
  }
  .aside__inner-chat a {
    font-size: initial;
    padding: 0px 30px;
    justify-content: flex-start;
    svg {
      margin-right: 10px;
    }
  }
  .aside__inner-nav ul li a.active::after {
    width: 3px;
  }
  .aside.open {
    left: 0px;
  }
  .aside-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    transition: all 0.3s ease;
    z-index: 19;
    @include bg-overlay;
  }
  .aside-bg.open {
    width: 100%;
  }
  .contact .contact__inner-card > img {
    bottom: -110px;
  }
  body {
    font-size: 14px;
  }
  .contact__inner-card-info-contact {
    margin: 60px 0px;
  }
  .contact .contact__inner-card .contact__inner-card-info {
    max-width: 100%;
  }
  .contact .contact__inner-card .contact__inner-card-form {
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;

    padding-left: 10px;
    padding-top: 30px;
    padding-right: 10px;
    padding-bottom: 50px;
  }
  .contact .contact__inner-card {
    flex-direction: column;
  }
  .header {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
  }
  .header__inner {
    min-height: 80px;
  }
  .header__inner .header__inner-logo {
    margin-right: auto;
    margin-left: 30px;
  }
  .header__menu-btn {
    display: block;
    z-index: 2;
  }
  .header__inner.active {
    .header__menu-btn span {
      background: $white;
    }
    .header__inner-logo a svg path {
      fill: $white;
    }
    .header__inner-link {
      margin-left: 0px;
      a svg path:first-child {
        fill: $white;
      }
      a svg path:last-child {
        stroke: $white;
      }
      a + a {
        margin-left: 35px;
      }
    }
  }
  .header__inner-nav .global-btn {
    display: block;
    width: 100%;
  }
  .header__inner-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: $black;
    left: 0;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 0%;
    padding: 60px 20px 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -100%);
  }
  .header__inner-nav.active {
    transform: translate(0px, 0px);
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 2;

    .nav-list-item.drop-down {
      .nav-list-item-btn {
        color: $white;
        svg path {
          fill: $white;
        }
      }
    }
    .nav-list-item.drop-down.active {
      .nav-list-item-drop-down {
        position: static;
        background-color: transparent;
        display: flex;
        ul li a {
          text-align: left;
        }
      }
    }
    .nav-list {
      flex-direction: column;
      width: 100%;
      margin: auto 0px;
      align-items: flex-start;
      overflow-y: auto;
      > a {
        margin-left: 0px;
        margin-top: 15px;
      }
      .nav-list-item {
        a {
          width: 100%;
          color: $white;
          display: block;
          text-align: center;
        }
      }
      .nav-list-item + .nav-list-item {
        margin-left: 0px;
        margin-top: 15px;
      }
    }

    .nav-list .nav-list-item .nav-list-item-drop-down ul li a {
      white-space: normal;
    }

    .nav-list-item-drop-down {
      max-width: 100%;
      transition: none !important;
      display: none;
    }
  }
  .job-advert-right {
    flex-direction: column;
  }

  .job-advert-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .job-advert-title {
    margin-right: 40px;
  }
}
@media (max-width: 600px) {
  h1 {
    font-size: 24px;
    line-height: 35px;
  }

  .contact .contact__inner p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
    max-width: 185px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .checkbox-item {
    padding-left: 18px;
  }
  .checkbox-item label::after {
    left: -18px;
  }
  .checkbox-item label::before {
    left: -18px;
  }
  .filter-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .filter-top-right {
    max-width: 100%;
  }
  .filter-top-left {
    flex-wrap: wrap;
    gap: 10px 20px;
  }
}
@media (max-width: 500px) {
  .job-advert-btns {
    flex-direction: column;
    .d-button,
    .g-button {
      max-width: 100%;
    }
  }
  .job-advert-skills-item {
    flex-direction: column;
    align-items: flex-start;
  }
}
